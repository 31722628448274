import 'popper.js/dist/esm/popper.js'
import 'bootstrap/dist/js/bootstrap.bundle'

// TODO: hide popover when clicking off of it
// See https://stackoverflow.com/questions/11703093/how-to-dismiss-a-twitter-bootstrap-popover-by-clicking-outside

document.addEventListener('DOMContentLoaded', function(event) {
  // Initialize Bootstrap popovers
  // (http://getbootstrap.com/javascript/#popovers)
  $('[data-toggle="popover"]').popover()

  // Initialize Bootstrap tooltips
  // (http://getbootstrap.com/javascript/#tooltips)
  $('[data-toggle="tooltip"]').tooltip()
})
