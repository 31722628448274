// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

// Setup Rails unobtrusive scripting adapter
// See https://github.com/djforth/rails/blob/master/actionview/app/assets/javascripts/README.md
//     https://github.com/rails/rails/tree/master/actionview/app/assets/javascripts
import Rails from '@rails/ujs'
Rails.start()

import { Turbo } from '@hotwired/turbo-rails'
Turbo.session.drive = false

import * as ActiveStorage from '@rails/activestorage'
ActiveStorage.start()

import 'controllers' // Stimulus
import 'bootstrap_and_overrides'

import 'actiontext_setup'
import 'analytics'
import 'flatpickr_setup'
import 'forms'
import 'manipulators'
import 'nested_fields'
import 'progress_bars'
import 'user_session'
import 'bootstrap-select'

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
