//
// User Session
//

import { DateTime } from 'luxon'

document.addEventListener('DOMContentLoaded', function(event) {
  // Timeline:
  //  - 60 minutes:
  //    * session timeout refreshed from the server
  //    * session expires and user re-directed to login
  //  - 40 minutes:
  //    * session timeout refreshed from the server
  //    * user warned, and able to get another hour
  //  - 0 minutes:
  //    * session timeout read from page data. Timer started for 20 minutes before expiration.
  const modal = document.querySelector('#session-timeout-modal')
  if (!modal) return

  const $modal = $(modal)
  const config = { minutes_of_warning: 20 }

  const continue_url = modal.dataset.continueUrl
  const timeout_url = modal.dataset.timeoutUrl
  const seconds_remaining = modal.dataset.secondsRemaining
  let expires_at = DateTime.local().plus({ seconds: seconds_remaining })
  let warns_at = expires_at.minus({ minutes: config.minutes_of_warning })

  let warningTimer
  let countdownTimer
  let refreshTimeoutTimer

  // Update timeout and show modal
  function sessionWarning() {
    refreshSessionExpiration().then(function () {
      if (msec_until_warning() > 0) {
        $modal.modal('hide')
        warningTimer = setTimeout(sessionWarning, msec_until_warning())
      }
      else {
        updateCountdown()
        $modal.modal({ backdrop: 'static' })
        countdownTimer = setInterval(updateCountdown, 1000)
      }
    })
  }

  function sessionExpiration() {
    refreshSessionExpiration().then(function() {
      if (msec_until_expiration() > 0) {
        sessionWarning()
      }
      else {
        // login via /logout to avoid timing issues and ensure session is really dead
        const redirectUri = encodeURIComponent(window.location)
        window.location = `/logout?redirect_uri=${redirectUri}`
      }
    })
  }

  function expirationMessageFormat() {
    const minutes = untilExpiration().as('minutes')

    if (minutes >= 2) {
      return "m 'minutes'"
    }
    else if (minutes >= 1) {
      return "m 'minute' s 'seconds'"
    }
    else {
      return "s 'seconds'"
    }
  }

  function updateCountdown() {
    if (untilExpiration().as('milliseconds') <= 0) {
      sessionExpiration()
    }

    const format = expirationMessageFormat()
    const message = untilExpiration().toFormat(format)
    document.querySelector('#session_timeout_countdown').textContent = message
  }

  // Time (duration) until warns_at
  function untilWarning() {
    return warns_at.diffNow()
  }

  // Time (duration) until expires_at
  function untilExpiration() {
    return expires_at.diffNow()
  }

  // Number of milliseconds until warns_at, or zero once the time has passed.
  function msec_until_warning() {
    return Math.max(untilWarning().as('milliseconds'), 0) // setTimeout may not like negative numbers
  }

  // Number of milliseconds until expires_at, or zero once the time has passed.
  function msec_until_expiration() {
    return Math.max(untilExpiration().as('milliseconds'), 0) // setTimeout may not like negative numbers
  }

  function continueSession() {
    return fetch(continue_url).then((response) => response.text())
                              .then((expiresAtString) => setSessionExpiration(expiresAtString))
                              .then(sessionWarning)
  }

  // Request latest session timeout.
  // Another active tab/window may have extended the session already.
  // TODO: consider keeping server requests down with _.throttle(function() {}, 10000)
  function refreshSessionExpiration() {
    return fetch(timeout_url).then((response) => response.text())
                             .then((expiresAtString) => setSessionExpiration(expiresAtString))
  }

  // Update expires_at with given dateString. Accepts ISO 8601 strings.
  function setSessionExpiration(dateString) {
    expires_at = DateTime.fromISO(dateString)
    warns_at = expires_at.minus({ minutes: config.minutes_of_warning })
  }

  modal.querySelector('.modal-footer .btn-primary').addEventListener('click', continueSession)

  // Kick things off...
  warningTimer = setTimeout(sessionWarning, msec_until_warning())
})
