import { Controller } from "@hotwired/stimulus"
import { get } from '@rails/request.js'

// Derived from:
// * https://boringrails.com/articles/hovercards-stimulus/
// * https://stimulus-popover.netlify.app/
// * https://github.com/twbs/bootstrap/blob/v4.6.0/js/src/popover.js
//
// TODO: consider Tippy v5 (compat with Popper.js v1) to shift off Bootstrap JS
// - https://atomiks.github.io/tippyjs/v5/getting-started/
// - https://github.com/excid3/tailwindcss-stimulus-components/blob/master/src/popover.js
// - excid3 on https://www.reddit.com/r/rails/comments/kt4avx/do_you_wish_bootstrap_had_a_stimulusjs_library/
export default class extends Controller {
  static targets = ['card']
  static values = { url: String, content: String }

  async setup(event) {
    if (this.popper) { return }

    await this.fetch()

    if (!this.contentValue) { return }

    this.popper = $(event.target).popover({
      content: this.contentValue,
      placement: 'top',
      trigger: 'hover focus',
      html: true,
      container: this.element, // so tooltip continues to show if you interact with it
      animation: false, // avoid flicker while mousing to the tooltip element
      delay: { hide: 50 }
    })

    // Manually show initially (unless it's too late). Later triggers handled by the popper itself.
    if (this.shouldBeShown) { this.popper.popover('show') }
  }

  async fetch() {
    if (this.contentValue) { return }
    if (!this.urlValue) { console.error(`[${this.identifier}] No content or url provided.`) }

    const response = await get(this.urlValue)

    // Don't use errors or redirects (e.g. session expired)
    if (response.ok && !response.redirected) {
      this.contentValue = await response.text
    }
  }

  // Determine if element currently has focus or mouse hover.
  get shouldBeShown() {
    return this.element === document.activeElement || this.element.matches(':hover')
  }

  disconnect() {
    this.popper.destroy()
  }
}
