// Toggle target visibility with checked state
function toggleVisibilityOf() {
  document
    .querySelectorAll(this.dataset.target)
    .forEach(target => target.classList.toggle('d-none'))
}

// Clear target
function clearTarget() {
  document
    .querySelectorAll(this.dataset.clearTarget)
    .forEach(target => { target.value = '' })
}

// Show target
function showTarget() {
  document
    .querySelectorAll(this.dataset.showTarget)
    .forEach(target => target.classList.remove('d-none'))
}

// Hide target
function hideTarget() {
  document
    .querySelectorAll(this.dataset.hideTarget)
    .forEach(target => target.classList.add('d-none'))
}

// Check target (e.g. radio, checkbox)
function checkTarget() {
  document
    .querySelectorAll(this.dataset.checkTarget)
    .forEach(target => {
      target.checked = true
      target.dispatchEvent(new Event('change')) // must manually fire 'change'
    })
}

// Apply targets
function applyTargets() {
  hideTarget.call(this)
  showTarget.call(this)
  clearTarget.call(this)
  checkTarget.call(this)
}

document.addEventListener('DOMContentLoaded', function(event) {
  document.querySelectorAll('[type="checkbox"][data-action="toggle-visibility-of"]')
          .forEach(el => el.addEventListener('change', toggleVisibilityOf))

  document.querySelectorAll('[data-action="show"][data-show-target]')
          .forEach(el => el.addEventListener('click', showTarget))

  document.querySelectorAll('[data-action="hide"][data-hide-target]')
          .forEach(el => el.addEventListener('click', hideTarget))

  document.querySelectorAll('[data-action="check"][data-check-target]')
          .forEach(el => el.addEventListener('click', checkTarget))

  document.querySelectorAll('[data-action="apply"]')
          .forEach(el => el.addEventListener('click', applyTargets))

})
