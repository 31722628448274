import { Controller } from "@hotwired/stimulus"
import Rails from '@rails/ujs'

export default class extends Controller {
  static targets = [ 'form' ]

  connect() {
    console.log('connected resource-filter controller')
  }

  filter(event) {
    Rails.fire(this.formTarget, 'submit') // Submit via Rails UJS
  }

  updateList(event) {
    $('[data-toggle="tooltip"]').tooltip() // Initialize Bootstrap tooltips
  }

  loadResourceList() {
    console.log('updating the resource list...')
  }
}
