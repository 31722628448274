import 'trix'
import '@rails/actiontext'

window.addEventListener("trix-file-accept", function(event) {
  // Disable *all* attachments.
  event.preventDefault()
  alert("File attachment not supported!")

  return // TODO: either enable images, or remove image attachment checks

  // Only allow images
  // https://developer.mozilla.org/en-US/docs/Web/Media/Formats/Image_types
  const acceptedTypes = ['image/jpeg', 'image/png', 'image/apng', 'image/avif', 'image/gif', 'image/webp']
  if (!acceptedTypes.includes(event.file.type)) {
    event.preventDefault()
    alert("Only allows image attachments")
  }

  // Only allow < 1 MB
  const maxFileSize = 1024 * 1024 // 1 MB
  if (event.file.size > maxFileSize) {
    event.preventDefault()
    alert("Images cannot exceed 1 MB")
  }
})
