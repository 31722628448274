import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['addedItem', 'template']

  addAssociation(event) {
    event.preventDefault()
    const content = this.templateTarget.innerHTML.replace(/{template}/g, new Date().valueOf())
    this.addedItemTarget.insertAdjacentHTML('beforebegin', content)
  }

  // Hide nested fields for association and mark for destruction.
  removeAssociation(event) {
    event.preventDefault()
    const item = event.target.closest('.nested-fields')
    item.querySelector("input[name*='_destroy']").value = 1
    item.style.display = 'none'
  }
}
