// Disable all inputs to prevent accidental double-submission, then submit the form.
function lockAndSubmitForm(event) {
  this.querySelectorAll('input, textarea, select, button')
      .forEach(el => el.setAttribute('readonly', true))

  this.submit()
}

document.addEventListener('DOMContentLoaded', function(event) {
  // Automatically submit form when an input is changed. Enable by setting 'data-autosubmit'.
  //
  //    <%= form_with model: model, data: { autosubmit: true } do |form| %>
  document.querySelectorAll('form[data-autosubmit="true"]')
          .forEach(form => form.addEventListener('change', lockAndSubmitForm))
})
