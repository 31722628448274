// Works with `link_to_add_fields` view helper.
function addNestedFields(event) {
  const uniqueId = new Date().getTime()
  const regexp = new RegExp($(this).data('id'), 'g')
  const fieldsHtml = $(this).data('fields').replace(regexp, uniqueId)

  $(this).before(fieldsHtml) // append fields
  event.preventDefault() // don't follow the link
}

// Works with `link_to_remove_fields` view helper.
function removeNestedFields(event) {
  $(this).prev('input[type=hidden][id$=_destroy]').val('1') // mark for delete
  $(this).closest('.nested-fields').hide()
  event.preventDefault() // don't follow the link
}

document.addEventListener('DOMContentLoaded', function(event) {
  // Bind 'Add' Listener
  $('form').on('click', '.add-nested-fields', addNestedFields)

  // Bind 'Remove' Listener
  // TODO: handle keyboard navigation
  // * keypress ok?
  // * event.key == 'Enter' vs event.which
  // * add guard clause
  //   return if (event.type == 'keypress' && event.key != 'Enter')
  $('form').on('click', '.remove-nested-fields', removeNestedFields)
})
