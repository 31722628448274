import { Controller } from "@hotwired/stimulus"

// TODO: selecting a resource adds it to the hold
// - fire 'resource:selected' event?
export default class extends Controller {
  static targets = [ 'item' ]

  connect() {
  }

  select(event) {
    event.preventDefault()
    // TODO: selected or no?
    // TODO: pass to form. https://github.com/stimulusjs/stimulus/issues/35
    //       https://github.com/stimulusjs/stimulus/issues/200#issuecomment-434731830
    const { gid } = event.target.dataset
    console.log(`selected ${gid}`)
    event.target.classList.toggle('selected')
  }
}
